<template>
  <section class="modal--container auth--container reset--pass-container">
    <div class="title--text">Reset Password</div>
    <form @submit.prevent="resetPass" id="resetPassForm">
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('email') }">
        <v-text-field
          label="Email"
          outlined
          :hide-details="true"
          v-model="email"
          name="email"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('password') }">
        <v-text-field
          label="Password"
          outlined
          :hide-details="true"
          v-model="password"
          name="password"
          class="basic--input"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showPass = !showPass)"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('password')">{{
          validation.firstError('password')
        }}</span>
      </div>
      <div
        class="auth--input mb-3"
        :class="{ 'has-error': validation.hasError('confirmPassword') }"
      >
        <v-text-field
          label="Kata Ulang Password"
          outlined
          :hide-details="true"
          v-model="confirmPassword"
          name="confirm_password"
          class="basic--input"
          :type="showConfirmPass ? 'text' : 'password'"
          :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showConfirmPass = !showConfirmPass)"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('confirmPassword')">{{
          validation.firstError('confirmPassword')
        }}</span>
      </div>
      <hr class="separator" />
      <button :disabled="isLoading" type="submit" class="btn btn-primary main--btn">
        Reset Password
      </button>
    </form>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
      isLoading: false,
      messageAlert: null,
      showAlert: false,
      alertType: '',
      showPass: false,
      showConfirmPass: false,
    };
  },
  validators: {
    email: {
      cache: true,
      debounce: 250,
      validator: function (value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.loginRegister.email.required'))
          .email(this.$i18n.t('errors.loginRegister.email.invalid'))
          .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }));
      },
    },
    password(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.password.required'))
        .minLength(8, this.$i18n.t('errors.loginRegister.password.min', { minChar: 8 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
    },
    'confirmPassword, password': function (confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword)
          .required(this.$i18n.t('errors.loginRegister.confirmPass.required'))
          .match(password, this.$i18n.t('errors.loginRegister.confirmPass.notMatched'));
      }
    },
  },
  methods: {
    async showModalSuccess() {
      await this.$swal(
        this.$i18n.t('loginRegister.resetPassHeader'),
        this.$i18n.t('loginRegister.resetPassSubheader'),
        'success',
      );
    },
    async resetPass() {
      try {
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          // eslint-disable-next-line no-unused-vars
          let response = await this.$store.dispatch('global/resetPassword', {
            email: this.cleanInput(this.email),
            password: this.cleanInput(this.password),
            token: this.$route.params.token,
            password_confirmation: this.cleanInput(this.confirmPassword),
          });
          await this.showModalSuccess(response);
          window.location.replace('/');
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
